<template>
  <b-card class="shop-promocodes">
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="promocodesRef"
          :button-label="$t('Add Promocodes')"
          :columns="promocodesColumns"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="'/shop/promocode'"
          @add-new-info="showAddModal"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="showItemsModal"
      :no-close-on-backdrop="true"
      :title="dataInfo && dataInfo.id ? 'Edit Promocodes' : 'Add Promocodes'"
      size="lg"
      ok-only
      hide-footer
      :ok-title="$t('Save Changes')"
      @hidden="showItemsModal = false"
      @ok="saveData"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Promocode')">
            <b-form-input
              v-model="dataInfo.promo_code"
              :class="{ 'is-invalid': !dataInfo.promo_code && isDataSubmitted }"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Percentage')">
            <b-form-input
              v-model="dataInfo.percentage"
              :class="{ 'is-invalid': !dataInfo.percentage && isDataSubmitted }"
              type="text"
              @keypress="$superApp.validations.numberOnly"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Minimum Order Amount')">
            <b-form-input
              v-model="dataInfo.min_amount"
              :class="{ 'is-invalid': !dataInfo.min_amount && isDataSubmitted }"
              type="text"
              @keypress="$superApp.validations.numberOnly"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Maximum Discount Amount')">
            <b-form-input
              v-model="dataInfo.max_amount"
              :class="{ 'is-invalid': !dataInfo.max_amount && isDataSubmitted }"
              type="text"
              @keypress="$superApp.validations.numberOnly"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Description')">
            <b-form-input
              v-model="dataInfo.promo_description"
              :class="{ 'is-invalid': !dataInfo.promo_description && isDataSubmitted }"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Expiration')">
            <b-form-datepicker
              v-model="dataInfo.expiration"
              :class="{ 'is-invalid': !dataInfo.expiration && isDataSubmitted }"
              :date-disabled-fn="dateDisabled"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Maximum Usage')">
            <b-form-input
              v-model="dataInfo.user_limit"
              :class="{ 'is-invalid': !dataInfo.user_limit && isDataSubmitted }"
              type="text"
              @keypress="$superApp.validations.numberOnly"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Eligibility')">
            <b-form-select
              v-model="dataInfo.eligibility"
              :options="userEligibilityOtions"
              :class="{ 'is-invalid': !dataInfo.eligibility && isDataSubmitted }"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="dataInfo.eligibility == '2'"
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Specific User')">
            <b-form-input
              v-model="searchCriteria"
              type="text"
              @keyup="onSearch"
              @blur="hideAutoComplete"
            />
            <user-auto-complete
              v-show="showAutoComplete"
              ref="userAutoComplete"
              :search-criteria="searchCriteria"
              @user-selected="userSelected"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="dataInfo.eligibility == '3'"
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Start Date')">
            <b-form-datepicker
              v-model="dataInfo.startdate"
              :class="{ 'is-invalid': !dataInfo.startdate && isDataSubmitted }"
              locale="en"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Country Name')">
            <b-form-select
              v-model="dataInfo.country_id"
              :class="{ 'is-invalid': !dataInfo.country_id && isDataSubmitted }"
              disabled
              :options="countryOptions"
              @change="onCountryChange"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('City Name')">
            <b-form-select
              v-model="dataInfo.city_id"
              :class="{ 'is-invalid': !dataInfo.city_id && isDataSubmitted }"
              disabled
              :options="cityOptions"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Picture')">
            <image-input
              :default-image="dataInfo.picture"
              :class="{ 'is-invalid': !dataInfo.picture && isDataSubmitted }"
              @input-file="inputFile"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <modal-footer
        :show-cancel="false"
        @ok="saveData"
      />
    </b-modal>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BFormGroup, BFormInput, BModal, BFormDatepicker, BFormSelect } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import ModalFooter from '@/@core/components/modal-footer/ModalFooter.vue'
import moment from 'moment'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import ImageInput from '../users/shared/ImageInput.vue'
import UserAutoComplete from '../shared/UserAutoComplete.vue'
import { useStoreUi } from './useStore'
import { useLoginUi } from '../auth/useAuth'

export default {
  components: {
    BRow,
    BCol,
    GoodDataTable,
    ModalFooter,
    UserAutoComplete,
    BCard,
    BFormGroup,
    BFormInput,
    ImageInput,
    BModal,
    BFormDatepicker,
    BFormSelect,
  },
  data() {
    return {
      isDataSubmitted: false,
      searchCriteria: '',
      showAutoComplete: false,
      showItemsModal: false,
      countryList: [],
      countryOptions: [],
      cityOptions: [],
      selectedUserInfo: null,
      dataInfo: {
        service: '',
        eligibility: '',
        promo_code: '',
        percentage: '',
        min_amount: 0,
        id: 0,
        max_amount: 0,
        promo_description: '',
        expiration: '',
        user_limit: 0,
        picture: '',
        country_id: '',
        city_id: '',
      },
      userEligibilityOtions: [
        {
          text: 'Everyone',
          value: 1,
        },
        {
          text: 'Specific User',
          value: 2,
        },
        {
          text: 'New User',
          value: 3,
        },
      ],
      promocodesColumns: [
        {
          label: 'Promocode',
          field: 'promo_code',
        },
        {
          label: 'Picture',
          field: 'picture',
          align: 'center',
          preventExport: true,
          useHtml: true,
          excelHtml: props => window.SuperApp.helper.image(props.picture),
          renderer: props => (props.picture ? `<img src="${window.SuperApp.helper.image(props.picture)}" onerror="this.src = '${window.SuperApp.constants.noImageAvailable}'" style="width: 40px; height: 40px; border-radius: 10%;" />` : 'NA'),
        },
        {
          label: 'Type',
          field: 'service',
          useRenderer: true,
          useResolver: true,
          renderer: props => {
            if (props.eligibility === 1) {
              return 'Everyone'
            }
            if (props.eligibility === 2) {
              return 'Specific User'
            }
            if (props.eligibility === 3) {
              return 'New User'
            }
            return ''
          },
        },
        {
          label: 'Percentage',
          field: 'percentage',
        },
        {
          label: 'Max Amount',
          field: 'max_amount',
        },
        {
          label: 'Expiration',
          field: 'expiration',
          rField: 'expiration_text',
          useRenderer: true,
          useResolver: true,
          renderer: props => {
            if (props.expiration) {
              return moment(props.expiration).format('DD/MM/YYYY')
            }
            return ''
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.editPromocodes(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete this promocode?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.deletePromocodes(props)
                  }
                })
              },
            },
          ],
        },
      ],
    }
  },
  mounted() {
    this.getUserCountries()
  },
  methods: {
    userSelected(payload) {
      this.selectedUserInfo = payload
      this.showAutoComplete = false
      this.searchCriteria = `${this.selectedUserInfo.first_name} ${this.selectedUserInfo.last_name}`
    },
    onSearch() {
      if (this.searchCriteria) {
        if (this.searchCriteria.length <= 1) {
          return
        }
        this.showAutoComplete = true
        this.$refs.userAutoComplete.onSearch()
      } else {
        this.showAutoComplete = false
      }
    },
    hideAutoComplete() {
      setTimeout(() => {
        // this.showAutoComplete = false
      }, 250)
    },
    setDefaultCountry() {
      const storeInfo = window.SuperApp.getters.userInfo()
      this.dataInfo.country_id = storeInfo.country_id
      this.onCountryChange()
      this.dataInfo.city_id = storeInfo.city_id
    },
    dateDisabled(ymd, date) {
      return date < new Date()
    },
    deletePromocodes(props) {
      showLoader()
      const { deletePromocode } = useStoreUi()
      const formData = new FormData()
      formData.append('_method', 'delete')
      formData.append('id', props.id)
      formData.append('confirm', 1)
      deletePromocode({
        query: props.id,
        data: formData,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.promocodesRef.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            this.showItemsModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    onCountryChange() {
      this.cityOptions = []
      let cityList = []
      const isCountryExist = this.countryList.find(x => x.id === this.dataInfo.country_id)
      if (isCountryExist) {
        cityList = isCountryExist.city.map(x => ({
          text: x.city_name,
          value: x.id,
        }))
      }
      this.cityOptions.push({
        text: 'Select City',
        value: '',
      })
      cityList.forEach(element => {
        this.cityOptions.push({
          text: element.text,
          value: element.value,
        })
      })
      // eslint-disable-next-line eqeqeq
      if (!this.cityOptions.find(x => x.value == this.dataInfo.city_id)) {
        this.dataInfo.city_id = ''
      }
    },
    getUserCountries() {
      const { userCountries } = useLoginUi()
      userCountries({
        salt_key: window.SuperApp.getters.saltKey(),
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.countryList = data.responseData
            const country = data.responseData.map(x => ({
              text: x.country_name,
              value: x.id,
            }))
            country.forEach(element => {
              this.countryOptions.push({
                text: element.text,
                value: element.value,
              })
            })
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    editPromocodes(props) {
      showLoader()
      const { getPromocodeInfo } = useStoreUi()

      getPromocodeInfo(props.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.shopItem = data.responseData
            Object.keys(this.dataInfo).forEach(x => {
              this.dataInfo[x] = this.shopItem[x]
            })
            this.dataInfo.picture = window.SuperApp.helper.image(this.dataInfo.picture)
            this.setDefaultCountry()
            if (this.dataInfo.expiration) this.dataInfo.expiration = moment(this.dataInfo.expiration, 'DD/MM/YYYY').format('YYYY-MM-DD')
            this.showItemsModal = true
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    showAddModal() {
      Object.keys(this.dataInfo).forEach(x => {
        this.dataInfo[x] = ''
      })
      this.searchCriteria = ''
      this.setDefaultCountry()
      this.showItemsModal = true
      this.isDataSubmitted = false
    },
    inputFile(payload) {
      if (this.dataInfo) this.dataInfo.picture = payload
    },
    updateItemsForShop() {
      const { updatePromocode } = useStoreUi()
      showLoader()
      const formData = this.getFormData()
      formData.append('_method', 'PATCH')
      updatePromocode({
        query: this.dataInfo.id,
        data: formData,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.promocodesRef.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            this.showItemsModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.showItemsModal = true
          showErrorNotification(this, error)
        })
    },
    saveData() {
      this.isDataSubmitted = true
      setTimeout(() => {
        if (window.SuperApp.validations.anyErrorInModal()) {
          return
        }
        this.showItemsModal = false
        this.isDataSubmitted = false
        if (this.dataInfo.id) {
          this.updateItemsForShop()
        } else {
          this.addItemsForShop()
        }
      })
    },
    getFormData() {
      const formData = new FormData()
      this.dataInfo.service = 'STORE'
      Object.keys(this.dataInfo).forEach(x => {
        if (x === 'expiration') {
          formData.append(x, moment(this.dataInfo[x]).format('MM/DD/YYYY'))
        } else if (this.dataInfo[x]) {
          if (x === 'picture' && window.SuperApp.helper.withBaseUrl(this.dataInfo.picture)) {
            formData.append(x, this.dataInfo[x])
          } else if (x !== 'picture') {
            formData.append(x, this.dataInfo[x])
          }
        }
      })
      // eslint-disable-next-line eqeqeq
      if (this.dataInfo.eligibility == '2' && this.selectedUserInfo) {
        formData.append('name', `${this.selectedUserInfo.first_name} ${this.selectedUserInfo.last_name}`)
        formData.append('user_id1', this.selectedUserInfo.id)
      }
      return formData
    },
    addItemsForShop() {
      const { createPromocode } = useStoreUi()
      showLoader()
      const formData = this.getFormData()
      createPromocode(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.promocodesRef.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            this.showItemsModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.showItemsModal = true
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
